
  import { mapGetters } from 'vuex'
  import NavigationPage from '@/database/models/NavigationPage'

  const SinglesFilters = () => import('@/components/layout/sideNavigation/subviews/filters/SinglesFilters')
  const ListCategories = () => import('@/components/layout/sideNavigation/subviews/ListCategories')
  const BuylistFilters = () => import('@/components/layout/sideNavigation/subviews/filters/BuylistFilters')
  const CMSArticleFilters = () => import('@/components/layout/sideNavigation/subviews/filters/CMSArticleFilters')

  export default {
    name: 'DynamicWrapper',
    components: {},
    props: {},
    data () {
      return {
        routePageType: null,
        types: {
          [NavigationPage.pageTypes.regular]: [ListCategories],
          [NavigationPage.pageTypes.mtgSingles]: [SinglesFilters],
          [NavigationPage.pageTypes.singlesSell]: [SinglesFilters],
          [NavigationPage.pageTypes.mtgBuylist]: [BuylistFilters],
          [NavigationPage.pageTypes.singlesBuylist]: [BuylistFilters],
          [NavigationPage.pageTypes.blog]: [CMSArticleFilters]
        }
      }
    },
    computed: {
      ...mapGetters({
        hasPage: 'menu/hasPage',
        page: 'menu/page',
      }),
      components (vm) {
        return vm.types[vm.pageType] || []
      },
      pageType (vm) {
        if (vm.routePageType) {
          return vm.routePageType
        }

        return NavigationPage.pageTypes.regular
      }
    },
    watch: {
      $route: {
        immediate: true,
        handler () {
          if ('pageType' in this.$route.meta) {
            this.routePageType = this.$route.meta.pageType
          } else if(this.hasPage && this.page.pageType) {
            this.routePageType = this.page.pageType
          } else {
            this.routePageType = null
          }
        }
      }
    }
  }
