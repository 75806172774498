
  import CMSArticle from '@/database/models/CMSArticle'
  import Row from '@/components/layout/Row'
  import { localizeRouteName } from '~/services/RouterService'

  export default {
    name: 'BlogPostPreview',
    functional: true,
    components: { Row },
    inject: ['getLoc'],
    props: {
      post: CMSArticle,
      language: {
        type: String,
        default: 'en'
      }
    },

    getImage(post, getLoc) {
      return getLoc(post.imageSmallLoc)
    },
    getTitle(post, getLoc) {
      return getLoc(post.nameLoc)
    },
    getPerex(post, getLoc) {
      const properties = getLoc(post.additionalPropertiesLoc)
      return properties.perex
    },
    linkProp (post, language) {
      return { name: localizeRouteName('blogDetail', language), params: { slug: post.slug } }
    }
  }
