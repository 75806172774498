
  import BlogListSide from '@/components/layout/sideNavigation/subviews/BlogListSide'
  import DynamicWrapper from '@/components/layout/sideNavigation/subviews/DynamicWrapper'

  const GamingClubWrapper = () => import('~/components/layout/sideNavigation/subviews/GamingClubWrapper')
  const AccountShortcut = () => import('@/components/layout/sideNavigation/subviews/AccountShortcut')
  const UserAreaMenu = () => import('@/components/layout/sideNavigation/subviews/UserAreaMenu')
  const InterestTags = () => import('@/components/layout/sideNavigation/subviews/InterestTags')
  const SearchWrapper = () => import('@/components/layout/sideNavigation/subviews/SearchWrapper')
  const ListCategories = () => import('@/components/layout/sideNavigation/subviews/ListCategories')
  const CreditVoucher = () => import('@/components/layout/sideNavigation/subviews/CreditVoucher')
  const BulkParserSettings = () => import('@/components/layout/sideNavigation/subviews/bulkOperations/BulkParserSettings')

  export default {
    name: 'SideNavigation',
    components: {},
    data () {
      return {
        hasAvailableContent: true,
        type: {
          homepage: [
            BlogListSide
          ],
          detail: [
            ListCategories
          ],
          list: [
            ListCategories
          ],
          list_shoppingCategory: [],
          dynamic: [
            DynamicWrapper
          ],
          user: [
            AccountShortcut,
            UserAreaMenu,
            InterestTags,
            CreditVoucher
          ],
          search: [
            SearchWrapper
          ],
          mtgSinglesBulk: [
            BulkParserSettings
		  ],
          gamingClub: [
            GamingClubWrapper
          ]
        }
      }
    },
    computed: {
      showNajadaHead (vm) {
        return ('najadaHead' in vm.$route.meta) && vm.$route.meta.najadaHead
      },
      sidebarComponents (vm) {
        const $route = vm.$route
        const type = vm.type
        const keys = Object.keys(vm.type)

        if('sidebar' in $route.meta && $route.meta.sidebar in this.type) {
          return type[$route.meta.sidebar]
        }

        for (let i = 0; i < keys.length; i++) {
          if ($route.matched.some(route => route.meta.sidebar === keys[i])) {
            return type[keys[i]] || []
          }
        }
        return []
      }
    },
    watch: {
      $route: {
        immediate: true,
        handler () {
          this.hasAvailableContent = this.isContentAvailable()
        }
      }
    },
    methods: {
      isContentAvailable () {
        const $route = this.$route
        const keys = Object.keys(this.type)

        if('sidebar' in $route.meta && $route.meta.sidebar in this.type) {
          return true
        }

        for (let i = 0; i < keys.length; i++) {
          if ($route.matched.some(route => route.meta.sidebar === keys[i])) {
            return keys[i] in this.type
          }
        }

        return false
      }
    }
  }
