
  import isArray from 'lodash/isArray'
  import isObject from 'lodash/isObject'

  export default {
    name: 'SideNavigationSection',
    props: {
      title: {
        type: String
      },
      items: {
        type: [Array, Object],
        default: () => []
      },
      transitionDisabled: Boolean
    },
    computed: {
      hasData (vm) {
        if (isObject(vm.items)) {
          return true
        } else if (isArray(vm.items)) {
          return vm.items.length > 0
        }

        return false
      }
    }
  }

