import { localizeDynamicRouteName, localizeRouteName } from '~/services/RouterService'
import NavigationMap from '~/structures/NavigationMap'

export default {
  data () {
    return {
      navigationMap: NavigationMap,
    }
  },
  methods: {
    localizeDynamicRouteName,
    localizeRouteName
  }
}
