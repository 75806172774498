export default {
  mtg: 1,
  mtgSingles: 10,
  mtgBuylist: 11,

  cardGames: 2,
  cgYgo: 35,
  cgPokemon: 43,
  cgAccessories: 90,

  tableTopGames: 3,

  miniatures: 5,

  comics: 4,

  merch: 391,
  doNotMiss: 392,
  doNotMissPresale: 7,
  doNotMissNew: 6,
  doNotMissDiscounted: 8,

  new: 6,
  presale: 7,
  discounted: 8,
  buylist: 9,
  blog: 138,

  giftIdeas: 250,
  giftVouchers: 260,

  gamingClub: 393
}
