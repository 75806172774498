
  import map from 'lodash/map'
  import { mapGetters } from 'vuex'
  import DelayHydration from 'nuxt-delay-hydration/dist/runtime/components/DelayHydration'
  import SideNavigationSection from '@/components/layout/sideNavigation/SideNavigationSection'
  import BlogPostPreview from '@/components/layout/cms/BlogPostPreview'
  import Button from '@/components/general/elements/Button'
  import NavigationMapper from '~/configurations/mixins/NavigationMapper'
  import CMSNewsArticle from '~/database/models/CMSNewsArticle'

  export default {
    name: 'BlogListSide',
    components: { Button, BlogPostPreview, SideNavigationSection, DelayHydration },
    mixins: [NavigationMapper],
    fetchOnServer: true,
    fetchKey: 'sidebar-blog-list',
    data () {
      return {
        posts: []
      }
    },
    async fetch() {
      let posts = []

      try {
        posts = await this.$store.dispatch('cms/getBlogPosts', {
          persist: false,
          worker: process.client
        })
      } catch (e) {
        posts = []
      }

      this.posts = posts
    },
    computed: {
      ...mapGetters({
        language: 'general/getLanguage'
      }),
      parsedPosts (vm) {
        return map(vm.posts, post => new CMSNewsArticle(post))
      }
    },
    mounted () {
      this.$fetch()
    },
    methods: {}
  }
